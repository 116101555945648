@media (min-width: 1000px) {
  .app-container {
    margin: 0rem 0rem 0 0rem !important;
    padding: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-font-color;
  font-weight: 400;
}

.width-25 {
  max-width: 15rem;
}
.hidden {
  visibility: hidden;
}

.center-loader {
  position: absolute;
  top: 50%;
  right: 50%;
  opacity: 1 !important;
}

.overlayed {
  opacity: 0.5;
  background-color: gray;
}

.lead {
  color: $subheading-font-color !important;
}

.list-group-item {
  transition: 0.3s all;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid $gray-200 !important;
  // border: 2px solid transparent !important;
  // border-radius: $border-radius;

  &:hover {
    h5,
    span,
    .widget-heading,
    .widget-subheading {
      color: $white !important;
      opacity: 1 !important;
    }

    border-bottom: unset !important;
    // background-color: $bg-brand;
    box-shadow: 0px 0px 0px 3px $brand;
    background-color: $brand;
    color: $white !important;
    transition: all 0.6s linear;
    // border: 2px solid $primary !important;
    border-radius: $border-radius;
    margin-bottom: 0.3rem;
  }
}

.wrapper {
  background-color: $white;
}

.app-main {
  // padding-top: 1rem !important;
}

.app-header__content {
  &.padding-right__search {
    padding-left: 0.9rem;
  }
}

.pe-7s-search {
  &.icon-search-size {
    font-size: 1.5rem;
  }
}

.bg-login {
  background-color: $login;
}

.bg-light-brand {
  background-color: $light-brand;
}

.bg-brand {
  .tooltip {
    opacity: 1;
  }
  .tooltip-inner {
    background: $brand;
    max-width: 300px;
    color: white;
    padding: 0.5rem;
    box-shadow: 0 0.46875rem 2.1875rem rgba(2, 6, 26, 0.06),
      0 0.9375rem 1.40625rem rgba(2, 6, 26, 0.06),
      0 0.25rem 0.53125rem rgba(2, 6, 26, 0.1),
      0 0.125rem 0.1875rem rgba(2, 6, 26, 0.06);
  }
  .arrow::before {
    border-bottom-color: $brand !important;
    border-top-color: $brand !important;
  }
}

.bg-white {
  .tooltip {
    opacity: 1;
  }
  .tooltip-inner {
    max-width: 300px;
    padding: 0.5rem;
    background: white;
    color: $heading-font-color;
    box-shadow: 0 0.46875rem 2.1875rem rgba(2, 6, 26, 0.06),
      0 0.9375rem 1.40625rem rgba(2, 6, 26, 0.06),
      0 0.25rem 0.53125rem rgba(2, 6, 26, 0.1),
      0 0.125rem 0.1875rem rgba(2, 6, 26, 0.06);
  }
  .arrow::before {
    border-left-color: white !important;
    border-right-color: white !important;
  }
}

.message {
  padding: 1rem;
  border-radius: $border-radius;
  border: 2px solid $brand;
  display: flex;
  align-items: center;

  &.success {
    border: 2px solid $green;
    background-color: $light-green;
    .text {
      font-size: $font-size-lg;
      vertical-align: middle;
    }
    svg {
      &.icons {
        color: $green;
        width: 30px;
        height: 30px;
        margin-right: 0.7rem;
      }
    }
  }
  &.error {
    border: 2px solid $red;
    background-color: $light-red;
    .text {
      font-size: $font-size-lg;
      vertical-align: middle;
    }
    svg {
      &.icons {
        color: $red;
        width: 30px;
        height: 30px;
        margin-right: 0.7rem;
      }
    }
  }
}

.card {
  box-shadow: unset;

  &:hover {
    transition: 1s;
    box-shadow: $box-shadow-default;
  }
}

.badge {
  font-size: $font-size-lg - 0.1rem;
  padding: 0.5rem;
}

.table-responsive {
  padding: 1rem;
}

.card-head {
  padding-top: 1.4rem;
  padding-bottom: 1.2rem;
  font-size: 1em !important;
  text-transform: none !important;
}

.card-body {
  background-color: $white;
}

.c-body-no-head {
  border-radius: $border-radius;
}

.standard-radius {
  border-radius: $border-radius;
}

tbody {
  background-color: $white;
  td {
    font-size: $font-size-sm;
  }
}

// .form-control {
//   align-items: flex-start;
//   display: flex;
//   flex-direction: column;
//   max-width: 350px;
//   padding-left: 0.7rem;
//   border-radius: 0.4rem;
//   height: 2rem;
//   box-shadow: 0 1px 1px 0 rgba(60, 66, 87, 0.1), 0 3px 16px 0 rgba(0, 0, 0, 0.1);
// }

// .control-label {
//   align-self: flex-end;
//   flex: 30% 0 0;
//   padding: 3px 1rem 0 0;
//   color: #2a3044;
//   line-height: 1.6;
//   text-align: right;
//   font-size: 0.9rem;
//   font-weight: 400;
// }

// .form-group {
//   display: flex;
//   margin-bottom: 1rem;
// }

.form-restricted {
  padding: 0rem 5vw 0rem 3vw;

  .form-control {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    max-width: 350px;
    padding-left: 0.7rem;
    border-radius: 0.4rem;
    height: 2rem;
    box-shadow: 0 1px 1px 0 rgba(60, 66, 87, 0.1),
      0 3px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .control-label {
    align-self: flex-end;
    flex: 30% 0 0;
    padding: 3px 1rem 0 0;
    color: #2a3044;
    line-height: 1.6;
    text-align: right;
    font-size: 0.9rem;
    font-weight: 400;
  }

  .form-group {
    display: flex;
    margin-bottom: 1rem;
  }
}

.form-document {
  label {
    font-weight: 500;
  }

  .form-control {
    align-items: flex-start;
    display: flex;
    flex: 0 0 55%;
    flex-direction: column;
    padding-left: 0.7rem;
    border-radius: 0.4rem;
    height: 3rem;
    // box-shadow: 0 1px 1px 0 rgba(60, 66, 87, 0.1),
    //   0 3px 16px 0 rgba(0, 0, 0, 0.1);
  }

  .bigger-input {
    height: 5rem;
    font-size: 3rem;
  }

  .bigger {
    font-size: $font-size-bigger;
    vertical-align: middle;
    height: unset;
  }
}

fieldset {
  width: 100%;
}

table {
  width: 100%;
}

.hoverable:hover {
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0,
    0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.hoverable-icon {
  background: transparent;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  vertical-align: middle;
  padding: 5px;

  &.danger {
    color: $red;
  }

  &.static {
    background: rgba(241, 241, 241, 0.8);

    &.danger {
      color: $red;
      background-color: $light-red;
    }
  }

  &.brand {
    background: $brand;
    color: white;

    &:hover {
      background: #2c42af;
    }
  }

  &:hover {
    background: $gray-300;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    padding: 5px;

    &.primary {
      color: $primary;
      background-color: $lighter-brand;
    }

    &.success {
      color: $green;
      background-color: $light-green;
    }

    &.danger {
      color: $red;
      background-color: $light-red;
    }
  }
}

.metismenu-link {
  &.active {
    background-color: transparent !important;
  }
}

// @media screen and (min-width: 1240px) {
//   .logo-text {
//     display: none;
//   }
// }

@media screen and (min-width: 780px) {
  .logo-text {
    background: white;
    border-radius: 2rem;
    padding: 1rem;
  }
}

.new-expiration-button {
  font-size: 1rem;
  background-color: $bg-brand;
  border: 0;
  color: $indigo;
  font-weight: 500;

  &:hover {
    background: $gray-200;
    color: $indigo;
  }
}

.add-something-btn {
  font-size: 1rem;
  background-color: transparent;
  border: 0;
  color: $indigo;
  font-weight: 500;

  &:hover {
    background: $gray-200;
    color: $indigo;
  }
}

.tab-image {
  border: unset;
  height: 25vh;
  width: 60%;
  opacity: 1;
}

.smaller-img {
  .tab-image {
    border: unset;
    height: 20vh;
    width: 50%;
    opacity: 1;
  }
}

.document-type-selector {
  border: 3px solid $gray-200;
  opacity: 0.7;
  &.__image {
    border: unset;
    height: 15vh;
    width: 60%;
    opacity: 1;
  }

  &.__text {
    border: unset;
    height: 15vh;
    width: 60%;
    opacity: 1;
    font-size: 6rem;
    color: #8b87e3;
  }
  &.__icon {
    border: unset;
    height: 15vh;
    width: 20%;
    opacity: 1;
    color: $brand;
  }

  &:hover {
    opacity: 1;
  }

  &.active {
    border: 3px solid $brand;
    opacity: 1;
    box-shadow: 0 0.46875rem 2.1875rem rgba(2, 6, 26, 0.03),
      0 0.9375rem 1.40625rem rgba(2, 6, 26, 0.03),
      0 0.25rem 0.53125rem rgba(2, 6, 26, 0.05),
      0 0.125rem 0.1875rem rgba(2, 6, 26, 0.03);
  }
}

.btn {
  &.bigger {
    font-size: 1rem !important;
  }

  &.btn-brand {
    background: $brand;
  }
}

.blurred {
  pointer-events: none;
  display: none;
  /* for "disabled" effect */
  opacity: 0.5;
}

.subheading {
  font-size: 0.88rem;
  opacity: 0.6;
}

.new-breadcrumb  {
  border-radius: 2rem !important;
}

.quick-exp-recap {
  a:hover {
    text-decoration: unset !important;
  }
  .expiration {
    // padding: 0 0.6rem 0 0;
    // border-radius: $border-radius-strong;
    // // background: #ec6a62;
    // background: #e05073;
    // color: $white;
    // box-shadow: 0 0.46875rem 2.1875rem rgba(2, 6, 26, 0.03),
    //   0 0.9375rem 1.40625rem rgba(109, 17, 32, 0.03),
    //   0 0.25rem 0.53125rem rgba(2, 6, 26, 0.05),
    //   0 0.125rem 0.1875rem rgba(2, 6, 26, 0.03);
    // justify-content: space-evenly;

    // transition: 0.5s all;
    padding: 0 0.6rem 0 0;
    border-radius: 1rem;
    background: $lighter-red;
    color: $red;
    box-shadow: 0 0.46875rem 2.1875rem rgba(2, 6, 26, 0.03),
      0 0.9375rem 1.40625rem rgba(109, 17, 32, 0.03),
      0 0.25rem 0.53125rem rgba(2, 6, 26, 0.05),
      0 0.125rem 0.1875rem rgba(2, 6, 26, 0.03);
    justify-content: space-evenly;
    -webkit-transition: 0.5s all;
    transition: 0.5s all;
    border: 2px solid $red;

    &:hover {
      box-shadow: 0 0.46875rem 2.1875rem rgba(2, 6, 26, 0.2),
        0 0.9375rem 1.40625rem rgba(109, 17, 32, 0.1),
        0 0.25rem 0.53125rem rgba(2, 6, 26, 0.1),
        0 0.125rem 0.1875rem rgba(2, 6, 26, 0.1);
    }

    .first {
      line-height: 1;
      text-align: center;
      background-color: $light-red;
      border-radius: $border-radius-strong;
      color: $red;
      padding: 0 0.5rem;

      .exp-date {
        font-size: $font-size-lg;
      }
      // border: 2px solid #e05073;
    }

    .second {
      line-height: 1.1;

      .doc-name {
        font-size: $font-size-sm;
        font-weight: 600;
      }
      .comp-name {
        font-size: $font-size-xs;
      }
    }

    .third  {
    }
  }
}

.widget-numbers.bigger {
  font-size: 6rem;
}

.widget-description.show-more-box {
  font-size: 1.1rem;
  .show-more {
    background-color: $brand;
    padding: 0rem 0.4rem;
  }
}

tr.hoverable {
  &.loading {
    background-color: $light-red;
    opacity: 0.8;
  }
  td {
    div.exp-date {
      font-size: $font-size-lg;

      &.expired {
        color: $red;
      }
    }
  }
}

a.unset-underline {
  text-decoration: none;
  color: unset;
}

.home-info {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $light-brand;
  background-clip: border-box;
  border-radius: $border-radius;
  border: unset !important;
  .display-4 {
    color: $white;
    font-weight: 600;
  }
  .lead {
    color: $white !important;
    margin-bottom: 0;
    &.second {
    }
  }
  .image {
    width: 75%;
    bottom: -3rem;
    position: absolute;
  }
}

@media (min-width: 991.98px) {
  .app-header__content {
    padding: $grid-gutter-width 70px 0 !important;
    .header-user-box {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .header-user-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}

.app-header__content {
  .header-user-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .header-user-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.header-btn-lg::before {
  display: none;
}

.refresh-error {
  background: $light-red;
  display: flex;
  flex-direction: row;
  align-items: center;
  div.icon {
    font-size: 3rem;
    color: $red;
    margin-right: 1.2rem;
  }
  div.text {
    h3 {
      color: $red;
    }

    h5 {
      color: $red;
    }
  }
}

@media (max-width: 991px) {
  .hide-on-half {
    display: none;
  }
}

.menu-link {
  font-family: Karla;
  font-size: 1.2rem;
  text-transform: unset !important;
}
